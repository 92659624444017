import { useState } from 'react';
import Ratio from 'react-bootstrap/Ratio';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './video.scss';
import { IoEyeOutline } from "react-icons/io5";


function VideoPopup(props) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={`mx-2 video-popup ${props.theme || ''}`}>
      <Button
          className="video-btn"
          variant="primary"
          onClick={() => setShow(true)}
        >
          <i className="fas fa-play fa-2x"></i>
        </Button>
        {(props?.theme === 'video-style-2') === !props.imageHide || (
          <img
            alt='videoImage'
            className="img-fluid image-link"
            src={props.videoImage}
          />
        )}
        <div style={{position: "absolute", top: 10, left: 10, background: "white", display: "flex", justifyContent: "center", borderRadius: "20px", alignItems: "center", gap: "10px", padding: "0 5px", boxShadow: "0 3px 10px black"}}>
          <IoEyeOutline size={20} /> {props.viewCount}
        </div>
      </div>

      <Modal
        show={show}
        size={props.size || 'md'}
        onHide={() => setShow(false)}
        dialogClassName="pgs-video-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Ratio aspectRatio="16x9">
          <video width="320" height="240" controls autoPlay>
            <source src={props.videoURL} type="video/mp4" />
          </video>
          </Ratio>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VideoPopup;
