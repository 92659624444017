export default function replaceWithSpace(html) {
    let content =  html.replaceAll(/\r\n/g, '')
    content =  content.replaceAll(/<p>/g, '<p>&Tab;')
    content =  content.replaceAll(/ <p/g, '<p')
     content =  content.replaceAll(/ <p/g, '<p')
     content =  content.replaceAll(/ <p/g, '<p')
     content =  content.replaceAll(/ <p/g, '<p')
     content =  content.replaceAll(/ <p/g, '<p')
     content =  content.replaceAll(/ <p/g, '<p')
    content =  content.replaceAll(/<br \/>/g, '')

    // adds domain to images
    content =  content.replaceAll(/"\/media\/ckeditor/g, '"https://education.gov.tm/media/ckeditor')

    console.log(html)
    console.log(content)
    return content
  }