/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';



import { useTranslation } from 'react-i18next';
import { getSingleInstitution } from '../../store/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import BlogSidebar from './sidebar_article';
import '../blog/blog.scss'
import { base } from '../../store/api';
import replaceWithSpace from '../../data/replace';
import Loading from '../../component/Loading';

function EducationSingle() {
  let { id } = useParams();
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0)
    const lang = language === 'tm' ? 'tk' : language
    setLoading(true)
    getSingleInstitution(dispatch, id, lang, (err, res) => {
      if (err) console.log(err);
      setData(res?.data)
      setLoading(false)
      console.log(res?.data)
    })


  }, [language, id])


  return (
    <>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("vocational_education")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              {loading ?
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: "center" }}>
                  <Loading style={{ width: "10%" }} />
                </div>
                :
                <Col xl={8} lg={12}>
                  <article className="about-post blog-post">
                    <div className='entry-content'>
                      <h4 className="entry-title pt-2 uppercase about-title">{Object.keys(data).length ? data.title : ''}</h4>
                    </div>
                    <div className="post-image">
                      <img className="w-100" src={Object.keys(data).length ? `${base}${data.thumbnail}` : ''} alt="" />
                    </div>
                    <div className="entry-content">
                      <div className="entry-description w-100">
                        <div dangerouslySetInnerHTML={{ __html: Object.keys(data).length ? replaceWithSpace(data.description) : '' }}></div>
                      </div>
                    </div>
                  </article>
                </Col>
              }
              {loading ? null :
                <Col xl={4} lg={8} md={8}>
                  <BlogSidebar id={id} />
                </Col>
              }
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default EducationSingle;
